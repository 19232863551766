<template>
    <router-link
    to="/"
    class="logo bxs-logo"
    :style="{ maxWidth: maxWidth }">
        <img src="/img/logo-h-white.png" alt="com.unity">
    </router-link>
</template>

<script>
export default {
    name: 'logo',
    props: {
        'max-width': {
            type: String,
            required: false,
            default: '160px'
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    vertical-align: middle;

    > img {
        position: relative;
        max-width: 100%;
        width: 100%;
    }
}
</style>