import Layout from './layout/Layout.vue'
import Row from './layout/Row/Row.vue'
import Col from './layout/Col/Col.vue'
import Logo from './Logo.vue'

import Btn from './Btn.vue'
import Chip from './Chip.vue'
import Icon from './Icon.vue'
import Menu from './Menu.vue'
import List from './List.vue'
import ListItem from './ListItem.vue'
import CollapseItem from './CollapseItem.vue'
import Spacer from './Spacer.vue'

import Form from './form/Form'
import BxsSwitchField from './form/BxsSwitchField.vue'
import BxsTextField from './form/TextField.vue'
import BxsTextareaField from './form/BxsTextareaField.vue'

import Toast from 'vue-toastification'

import 'vue-toastification/dist/index.css'

export default function createComp(app) {
    app.component('logo', Logo)

    app.component('bxs-layout', Layout)
    app.component('bxs-row', Row)
    app.component('bxs-col', Col)

    app.component('bxs-btn', Btn)
    app.component('bxs-menu', Menu)
    app.component('bxs-list', List)
    app.component('bxs-list-item', ListItem)
    app.component('bxs-collapse-item', CollapseItem)

    app.component('bxs-icon', Icon)
    app.component('bxs-chip', Chip)

    app.component('bxs-form', Form)
    app.component('bxs-switch-field', BxsSwitchField)
    app.component('bxs-text-field', BxsTextField)
    app.component('bxs-textarea-field', BxsTextareaField)

    app.component('bxs-spacer', Spacer)

    app.use(Toast, {
        position: 'bottom-center',
        timeout: 4000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        newestOnTop: false,
        transition: 'Vue-Toastification__fade',
        bodyClassName: ['bxs-notify'],
        maxToasts: 1,
        showCloseButtonOnHover: true,
        hideProgressBar: true,
        draggable: true
    })
}