<template>
    <div
    v-sticky
    class="navbar">
        <bxs-layout>
            <bxs-row align="center">
                <bxs-col cols="6">
                    <logo />
                </bxs-col>

                <bxs-col
                cols="6"
                class="text-right">
                    <!-- title="com.unity" -->
                    <bxs-menu activator-size="small">
                        <template #title>
                            <logo max-width="160px" />
                        </template>

                        <div class="my-ui">
                            <div class="mb-1">
                                <p class="mb-0">
                                    <a
                                    href="mailto:info@com-unity.tv"
                                    target="_blank">info@com-unity.tv</a>
                                </p>

                                <p class="mb-0">
                                    <a
                                    href="tel:+393339851645"
                                    target="_blank">333 98 51 645</a>
                                </p>
                            </div>

                            <!-- <div>
                                <p class="mb-0">via Francesco Baracca 1A</p>
                                <p class="mb-0">22071 Cadorago (Como)</p>
                            </div> -->
                        </div>

                        <div class="mb-ui"
                        style="font-size: 1.5rem;">
                            <a
                            href="https://www.instagram.com/com.unity.productions"
                            target="_blank"
                            class="mr-ui">
                                <bxs-icon name="ig"></bxs-icon>
                            </a>

                            <a
                            href="https://twitter.com/com_unitytv"
                            target="_blank"
                            class="mr-ui">
                                <bxs-icon name="tw"></bxs-icon>
                            </a>

                            <a
                            href="https://www.linkedin.com/company/com-unity-productions"
                            target="_blank">
                                <bxs-icon name="li"></bxs-icon>
                            </a>
                        </div>

                        <div class="text-mute">
                            <p class="mb-ui"><router-link to="/privacy-policy"><small>Privacy policy</small></router-link></p>
                            <p class="mb-0"><small>© {{ new Date().getFullYear() }} • com.unity</small></p>
                            <p class="mb-0"><small>P.IVA / C.F. 04003900133</small></p>
                        </div>
                    </bxs-menu>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </div>
</template>

<script>
export default {
    name: 'bxs-navbar'
}
</script>

<style lang="scss" scoped>
.navbar {
    position: relative;
    width: 100%;
    min-height: var(--navbar-height);
    background-color: var(--navbar-background-color);
    -webkit-backdrop-filter: var(--navbar-backdrop-filter);
    backdrop-filter: var(--navbar-backdrop-filter);

    display: flex;
    align-items: center;

    .logo {
        max-width: 200px;
    }
}
</style>