<template>
    <div class="privacy_policy--view">
        <section>
            <bxs-layout max-width="768">
                <div class="mb-12">
                    <h3>Privacy policy di com-unity.tv</h3>
                </div>

                <div>
                    <h5>Informazioni sul trattamento dei dati personali</h5>
                    <p>Questa pagina descrive, ai sensi del Regolamento (UE) 2016/679, le modalità di trattamento dei dati personali degli utenti raccolti durante la navigazione del presente sito www.com-unity.tv e com-unity.tv o conferiti direttamente dall’interessato attraverso la compilazione di moduli e indirizzi di contatto.</p>
                    <p>Le presenti informazioni non riguardano altri siti, pagine o servizi online raggiungibili tramite link ipertestuali eventualmente pubblicati nel sito ma riferiti a risorse esterne al presente sito.</p>
                </div>

                <div>
                    <ul>
                        <li>
                            <h5>1. Titolare del Trattamento dei Dati</h5>
                            <p>Titolare del trattamento è COM.UNITY S.R.L.S., via Francesco Baracca 1A, 22071 Cadorago (CO) – Telefono +39 333 98  51 645, Email direzione@com-unity.tv</p>
                        </li>
                        <li>
                            <h5>2. Responsabile del trattamento</h5>
                            <p>Responsabili del trattamento sono Sig. De Fazio Mario Andrea e il Sig. Popa Stefan</p>
                        </li>
                        <li>
                            <h5>3. Tipologie di Dati raccolti – Finalità – Base giuridica</h5>

                            <ul>
                                <li>
                                    <h5>3.1. Modulo "Richiesta informazioni”</h5>
                                    <p>Dati conferiti direttamente dall’interessato attraverso la compilazione e l’invio dei moduli di contatto. L’invio esplicito e volontario di messaggi agli indirizzi di contatto, nonché la compilazione e l’inoltro dei moduli presenti sul sito, comportano l’acquisizione dei dati di contatto del mittente, nonché di tutti i dati personali inclusi nelle comunicazioni, dati anagrafici e di dati che il GDPR UE 679/2016 definisce “particolari”, in quanto idonei a rivelare lo stato di salute. Per il loro trattamento la legge richiede una specifica manifestazione di consenso.</p>
                                    <p>Tali dati vengono trattati per le seguenti finalità e nel rispetto delle relative basi giuridiche del trattamento, per un periodo di conservazione non superiore a quello necessario agli scopi per i quali sono stati raccolti e trattati.</p>
                                </li>
                                <li>
                                    <h5>3.1.1 Finalità: rispondere alle richieste inviate dall’interessato tramite i moduli di contatto.</h5>
                                    <p>Base Giuridica: consenso dell’interessato.</p>
                                </li>
                                <li>
                                    <h5>3.2. Dati di navigazione del sito web</h5>
                                    <p>Il presente sito, per il suo corretto funzionamento, acquisisce durante la normale navigazione alcuni dati personali, tra cui gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) e altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente. I dati di navigazione vengono acquisiti non per finalità di identificazione degli utenti, ma all’unico fine di raccogliere, in forma anonima, informazioni statistiche sull’utilizzo del sito e dei suoi servizi.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h5>4. Categorie di destinatari dei dati personali</h5>
                            <p>Le seguenti categorie di soggetti possono venire a conoscenza dei Suoi dati: Titolare, Responsabile ed incaricati del trattamento, nominati per iscritto dalla scrivente società, soci, addetti alla contabilità ed alla fatturazione, addetti commerciali, soggetti nostri consulenti, in qualità di responsabili esterni, nei limiti necessari per svolgere il loro incarico presso la nostra organizzazione, previo lettera di incarico e/o stipula contratto che imponga il dovere di riservatezza e sicurezza, nonché soggetti che hanno necessità di accedere ai dati per consulenze legali, con finalità ausiliare al rapporto che intercorre tra le parti, quali l’esecuzione dei contratti in atto, nei limiti strettamente necessari per svolgere i compiti ausiliari loro affidati.</p>
                            <p>Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questo Sito Web (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.</p>
                        </li>
                        <li>
                            <h5>5. Modalità del trattamento</h5>
                            <p>Il trattamento dei dati avviene mediante l’utilizzo di strumenti e procedure idonei a garantirne sicurezza e riservatezza e potrà essere effettuato sia mediante supporti cartacei, sia attraverso l’ausilio di mezzi informatici automatizzati, atti a memorizzare, gestire e trasmettere i dati stessi.</p>
                            <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate.</p>
                        </li>

                        <!-- <li>
                            <h5>6. Comunicazione e diffusione</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde placeat nam qui, ducimus ab tempora, hic quibusdam ad, at sit possimus perspiciatis consectetur deserunt commodi! Inventore, maiores! Hic, delectus laudantium!</p>
                        </li> -->

                        <li>
                            <h5>6. Diritti degli interessati</h5>
                            <p>Gli artt. dal 15 al 22 del GDPR UE 679/2016 conferiscono agli interessati specifici diritti. In particolare, il diritto di ottenere conferma dell’esistenza o meno di dati personali, l’accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati, la comunicazione di tali dati e le finalità su cui si basa il trattamento. Inoltre, gli interessati hanno il diritto di ottenere la revoca del consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, nonché l’aggiornamento o, qualora sussista un interesse in merito, l’integrazione dei dati. Gli interessati hanno il diritto di opporsi, per motivi legittimi, al trattamento stesso.</p>
                            <p>Le segnalazioni per ogni eventuale variazione dei dati personali devono pervenire tempestivamente al Responsabile del trattamento in modo da poter ottemperare all’art. 11, lettera (c) della suddetta normativa, che richiede che i dati raccolti siano esatti e, quindi, aggiornati.</p>
                            <p>Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti effettuato attraverso questo sito avvenga in violazione di quanto previsto dal Regolamento hanno il diritto di proporre reclamo a un’autorità di controllo, come previsto dall’art. 77 del Regolamento stesso, o di adire le opportune sedi giudiziarie (art. 79 del Regolamento).</p>
                        </li>
                    </ul>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'privacy_policy'
}
</script>