<template>
    <footer class="pt-24 text-center pb-6">
        <bxs-layout>
            <div class="mb-24">
                <img
                src="/img/logo-h-white.png" alt=""
                class="mx-auto"
                style="max-width: 200px;">
            </div>

            <bxs-row class="mb-ui">
                <bxs-col
                cols="12" ds="4"
                class="mb-ui">
                    <div class="mb-4">
                        <h6 class="mb-0">Seguici</h6>
                    </div>

                    <div style="font-size: 1.5rem;">
                        <a
                        href="https://www.instagram.com/com.unity.productions"
                        target="_blank"
                        class="mr-4">
                            <bxs-icon name="ig"></bxs-icon>
                        </a>

                        <a
                        href="https://twitter.com/com_unitytv"
                        target="_blank"
                        class="mr-4">
                            <bxs-icon name="tw"></bxs-icon>
                        </a>

                        <a
                        href="https://www.linkedin.com/company/com-unity-productions"
                        target="_blank">
                            <bxs-icon name="li"></bxs-icon>
                        </a>
                    </div>
                </bxs-col>

                <bxs-col
                cols="12" ds="4"
                class="mb-ui">
                    <div class="mb-4">
                        <h6 class="mb-0">Indirizzo</h6>
                    </div>

                    <div>
                        <a
                        href="https://goo.gl/maps/oNU9EGum9TKxhpvP6"
                        target="_blank">
                            <p class="mb-0">via Francesco Baracca 1A</p>
                            <p class="mb-0">22071 Cadorago (CO)</p>
                        </a>
                    </div>
                </bxs-col>

                <bxs-col
                cols="12" ds="4"
                class="mb-ui">
                    <div class="mb-4">
                        <h6 class="mb-0">Contatti</h6>
                    </div>

                    <div>
                        <p class="mb-0">
                            <a href="mailto:info@com-unity.tv">info@com-unity.tv</a>
                        </p>

                        <p class="mb-0">
                            <a href="tel:+393339851645">333 98 51 645</a>
                        </p>
                    </div>
                </bxs-col>
            </bxs-row>

            <bxs-row class="text-mute">
                <bxs-col cols="6" ds="4">
                    <small>© {{ new Date().getFullYear() }} • com-unity.tv</small>
                </bxs-col>

                <bxs-col cols="6" ds="4">
                    <router-link to="/privacy-policy"><small>Privacy policy</small></router-link>
                </bxs-col>

                <bxs-col cols="6" ds="4">
                    <small>P.IVA 04003900133</small>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </footer>
</template>

<script>
export default {
    name: 'bxs-footer'
}
</script>

<style lang="scss" scoped>
.footer {
    position: relative;
    width: 100%;
    min-height: var(--footer-height);
    background-color: var(--footer-background-color);
    color: var(--footer-color);
}
</style>