import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Http from './assets/libs/Http'
import components from './components'
import directives from './directives'

const app = createApp(App)
app.config.globalProperties.$bxsHttpContactForm = new Http({
    base_url: process.env.VUE_APP_API_BXS_CONTACT_FORM,
    app_id: 'bxs-contact-form',
    prefix: 'bxscf'
})
components(app)
directives(app)

app.use(store).use(router).mount('#app')
