<template>
    <div
    :class="classes"
    @click="routableHandleClick">
        <slot />
    </div>
</template>

<script>
import { routable } from '@/mixins'

export default {
    name: 'mrs-chip',
    mixins: [routable],
    props: {
        color: {
            type: String,
            required: false,
            default: null
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        'x-small': {
            type: Boolean,
            required: false,
            default: false
        },
        large: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['mrs-chip', {
                ['mrs-chip-' + this.color]: !!this.color,
                'mrs-chip-outlined': this.outlined,
                'mrs-chip-small': this.small,
                'mrs-chip-x-small': this.xSmall,
                'mrs-chip-large': this.large,
                ...this.routable_classes
            }]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';

.mrs-chip {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: var(--chip-font-size);
    font-weight: var(--chip-font-weight);
    min-height: var(--chip-min-height);
    padding: var(--chip-padding-y) var(--chip-padding-x);
    background-color: var(--chip-background-color);
    white-space: nowrap;
    overflow: hidden;
    border-radius: var(--chip-border-radius);
    user-select: none;
    text-align: center;

    @each $key, $value in $colors {
        &.mrs-chip-#{$key} {
            background-color: var(--color-#{$key});
            color: var(--color-typo);

            &.mrs-chip-outlined {
                border-color: var(--color-#{$key});
                color: var(--color-#{$key});
            }
        }
    }

    &.mrs-chip-outlined {
        border: var(--thickness) solid currentColor;
        background-color: transparent;
        color: currentColor;
    }

    &.mrs-chip-x-small {
        min-height: inherit;
        font-size: calc(var(--chip-font-size) / 1.4);
        padding: calc(var(--chip-padding-y) / 1.4) calc(var(--chip-padding-x) / 1.4);
    }

    &.mrs-chip-small {
        min-height: inherit;
        font-size: calc(var(--chip-font-size) / 1.2);
        padding: calc(var(--chip-padding-y) / 1.2) calc(var(--chip-padding-x) / 1.2);
    }

    &.mrs-chip-large {
        font-size: calc(var(--chip-font-size) * 1.2);
        padding: calc(var(--chip-padding-y) * 1.2) calc(var(--chip-padding-x) * 1.2);
    }
}
</style>