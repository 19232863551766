<template>
    <div
    :class="['collapse-item-comp', {
        actived: on,
        'collapse-item-comp--no-border': hideBorder
    }]"
    @click="toggle">
        <div
        class="collapse-item-comp--head-cnt">
            <slot name="label" />

            <bxs-icon :name="!on ? 'arrow-down' : 'arrow-top'" />
        </div>

        <div
        ref="content"
        class="collapse-item-comp--cnt">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'collapse-item',
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        'hide-border': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            on: false
        }
    },
    methods: {
        toggle () {
            this.on = !this.on

            this.$nextTick(() => {
                const cnt = this.$refs.content

                if (cnt.style.maxHeight) {
                    cnt.style.maxHeight = null
                } else {
                    cnt.style.maxHeight = cnt.scrollHeight + 'px'
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.collapse-item-comp {
    position: relative;
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 0.5rem;
    // background-color: red;

    &--no-border {
        border: none;
    }

    &--head-cnt {
        position: relative;
        width: 100%;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;
    }

    &--cnt {
        max-height: 0;
        margin-top: 0.5rem;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
}
</style>