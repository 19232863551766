<template>
    <span
    :class="['bxs-icon', routable_classes]"
    :style="measurable_styles"
    @click="routableHandleClick">
        <svg
        ref="svg"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16">
            <path
            v-if="name"
            :d="$store.state.icons[name]" />
        </svg>
    </span>
</template>

<script>
import { routable, measurable } from '@/mixins'
// import { fixSvg } from '@/assets/libs/utils'

export default {
    name: 'bxs-icon',
    mixins: [routable, measurable],
    props: {
        name: {
            type: String,
            required: false,
            default: null
        }
    },
    mounted () {
        this.$nextTick(() => {
            // fixSvg(this.$refs.svg)
        })
    }
}
</script>

<style lang="scss" scoped>
.bxs-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: var(--icon-size);

    > svg {
        width: 100%;
        height: 100%;
        // min-width: 1rem;
        margin-bottom: -2px;
    }
}
</style>