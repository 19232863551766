<template>
    <div :class="['bxs-menu', {
        'bxs-menu-on': local_on,
        'bxs-menu-block': block
    }]">
        <!-- activator -->
        <div
        v-if="$slots.activator"
        class="bxs-menu--activator">
            <slot
            name="activator"
            :on="{
                click: () => local_on = !local_on
            }"
            :attrs="{
                role: 'button',
                'aria-haspopup': true,
                'aria-expanded': false
            }" />
        </div>

        <div v-else>
            <bxs-btn
            :icon="icon"
            :x-small="activatorSize === 'x-small'"
            :small="activatorSize === 'small'"
            :large="activatorSize === 'large'"
            @click="open">
                <bxs-icon :name="activatorIcon || 'three-dots-vertical'" />
            </bxs-btn>
        </div>

        <!-- content -->
        <Teleport to="body">
            <div
            v-if="local_on"
            ref="menu"
            class="bxs-menu--menu"
            @click.self="_close_empty">
                <bxs-layout class="bxs-menu--menu--content bxs-elevation">
                    <div class="bxs-menu--menu--content--header">
                        <h5
                        v-if="title"
                        class="ma-0">{{ title }}</h5>

                        <slot
                        v-if="$slots.title"
                        name="title" />

                        <bxs-spacer></bxs-spacer>

                        <bxs-btn
                        icon
                        @click="close">
                            <bxs-icon name="close" />
                        </bxs-btn>
                    </div>

                    <div
                    ref="scroller"
                    class="bxs-menu--menu--content--body">
                        <slot />
                    </div>

                    <div
                    v-if="$slots.actions"
                    class="bxs-menu--menu--content--footer">
                        <slot name="actions" />
                    </div>
                </bxs-layout>
            </div>
        </Teleport>
    </div>
</template>

<script>
export default {
    name: 'bxs-menu',
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        'activator-size': {
            type: String,
            required: false,
            default: null
        },
        'activator-icon': {
            type: String,
            required: false,
            default: null
        },
        icon: {
            type: Boolean,
            required: false,
            default: false
        },
        'close-on-click': {
            type: Boolean,
            required: false,
            default: true
        },
        block: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'open',
        'close',
        'infinite-scroll'
    ],
    data () {
        return {
            local_on: false
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    watch: {
        local_on (newVal) {
            if (newVal) {
                this.$emit('open')

                this.$nextTick(() => {
                    if (this.$refs.scroller) {
                        this.$refs.scroller.addEventListener('scroll', this.handleScroll)
                    }
                })
            } else {
                this.$emit('close')

                if (this.$refs.scroller) {
                    this.$refs.scroller.removeEventListener('scroll', this.handleScroll)
                }
            }
        }
    },
    methods: {
        play () {
            if (this.$refs.scroller) {
                this.$refs.scroller.addEventListener('scroll', this.handleScroll)
            }

            if (this.closeOnClick) {
                window.addEventListener('click', this._checkClose)
            }
        },
        stop () {
            if (this.$refs.scroller) {
                this.$refs.scroller.removeEventListener('scroll', this.handleScroll)
            }

            if (this.closeOnClick) {
                window.removeEventListener('click', this.checkClose)
            }
        },
        handleScroll () {
            if (!this.$refs.scroller) return

            const {
                scrollTop,
                scrollHeight,
                clientHeight
            } = this.$refs.scroller

            // console.log(scrollTop + clientHeight, clientHeight, scrollHeight)

            if (
                scrollTop + clientHeight >= (scrollHeight - (clientHeight / 2))
            ) {
                this.$emit('infinite-scroll')
            }
        },
        _checkClose (e) {
            if (!this.$el.contains(e.target)) {
                // console.log('Clicked outside l2 and logo-menu')
                this.local_on = false
            }
        },
        _close_empty () {
            if (this.closeOnClick) this.close()
        },
        open () {
            this.local_on = true
        },
        close () {
            this.local_on = false
        }
    },
    beforeUnmount () {
        this.stop()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.bxs-menu {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    &.bxs-menu-block {
        display: block;
    }

    &--activator {
    }

    &--menu {
        position: fixed;
        width: 100%;
        height: 100vh;
        // height: calc(var(--vh, 1vh) * 100);
        top: 0;
        left: 0;
        background-color: var(--menu-foreground-background-color);
        -webkit-backdrop-filter: var(--menu-foreground-backdrop-filter);
        backdrop-filter: var(--menu-foreground-backdrop-filter);
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        z-index: var(--layer-menu);

        &--content {
            position: relative;
            background-color: var(--menu-content-background-color);
            border-radius: var(--menu-content-border-radius);
            padding: var(--menu-content-padding-y) var(--menu-content-padding-x);

            &--header {
                position: relative;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
            }

            &--body {
                position: relative;
                max-height: calc(100vh - 96px);
                overflow: auto;
                background-color: var(--menu-content-background-color);

                > ul {
                    margin: 0;
                    padding: 0;

                    > li {
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            &--footer {
                position: relative;
                overflow: hidden;
                background-color: var(--menu-content-background-color);
            }
        }
    }
}
</style>