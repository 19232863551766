<template>
    <div class="home--view">
        <section class="hero pt-ui text-center">
            <bxs-layout>
                <h1 class="mb-12">we are unity</h1>
                <p class="h4 text-gradient text-uppercase t-page">
                    <span>Progettazione </span>
                    <span class="mx-ui">&nbsp;produzione&nbsp;</span>
                    <span >management</span>
                </p>
                <p>COM.UNITY si occupa in maniera olistica di streaming, <br> dalla progettazione alla produzione di contenuti per piattaforme digitali.</p>

                <div>
                    <bxs-btn
                    append-icon="arrow-down"
                    color="primary"
                    @click="scollToContact">Conosciamoci</bxs-btn>

                    <!-- <bxs-menu title="Contatti">
                        <template #activator="{ on }">
                            <bxs-btn
                            v-on="on"
                            append-icon="arrow-down"
                            color="primary">Conosciamoci</bxs-btn>
                        </template>

                        <bxs-list>
                            <bxs-list-item prepend-icon="envelope-fill">
                                <a href="mailto:info@com-unity.tv">Email</a>
                            </bxs-list-item>
                            <bxs-list-item prepend-icon="telephone-fill">
                                <a href="tel:+393339851645">333 98 51 645</a>
                            </bxs-list-item>
                            <bxs-list-item prepend-icon="send-fill">
                                <a
                                href="https://twitter.com/com_unitytv"
                                target="_blank">Instagram</a>
                            </bxs-list-item>
                        </bxs-list>
                    </bxs-menu> -->
                </div>
            </bxs-layout>
        </section>

        <!-- mission -->
        <section>
            <bxs-layout class="mb-ui">
                <bxs-chip
                class="mb-ui"
                small
                outlined>Mission</bxs-chip>

                <h3>Il rapporto con il cliente è fondamentale</h3>
                <p class="h3 text-ui mb-0">Non si ferma alla formalizzazione di un contratto, bensì diventa parte fondamentale del progetto, fino al conseguimento degli obiettivi preposti, raggiungendo così il sogno della community appartenente alla persona o al brand che ci contatta per collaborare.</p>

                <div class="blur-3"></div>
            </bxs-layout>
        </section>

        <!-- video -->
        <!-- <section>
            <video
            preload="auto"
            playsinline=""
            autoplay=""
            muted=""
            loop=""
            poster="">
                <source src="/img/cus-milano-comunity.mov" type="video/mp4">
                Your browser does not support HTML5 video.
            </video>
        </section> -->

        <!-- services -->
        <section>
            <bxs-layout>
                <div class="text-center mb-18">
                    <bxs-chip
                    class="mb-ui"
                    small
                    outlined>Servizi</bxs-chip>

                    <h2>Esperienza {{ new Date().getFullYear() - 2012 }} anni sul mercato</h2>
                    <p>Abbiamo il know how per offrire i contenuti migliori per te e la tua audience <br> seguendoti ogni giorno nel tuo percorso e consigliandoti sempre il modo più efficace per monetizzare.</p>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">Broadcasting</h5>
                            </template>

                            <p class="mb-0 text-mute">messa in onda di trasmissioni sulle piattaforme streaming più utilizzate sul mercato grazie a una regia professionale certificata da anni di lavoro sul campo.</p>
                        </bxs-collapse-item>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">Management</h5>
                            </template>

                            <p class="mb-0 text-mute">Gestione di canali Twitch e Youtube per i brand o content creator; strategy e fidelizzazione di una community; sponsorship e monetizzazione.</p>
                        </bxs-collapse-item>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">Consulenza</h5>
                            </template>

                            <p class="mb-0 text-mute">Meeting personalizzati per ogni necessità relativa al broadcasting e all’utilizzo delle piattaforme streaming.</p>
                        </bxs-collapse-item>
                    </bxs-col>

                    <!-- <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">Emissione</h5>
                            </template>

                            <p class="mb-0 text-mute">Messa in onda di trasmissioni su qualsiasi piattaforma tramite una regia professionale e le competenze da regista certificate da anni di lavoro sul campo</p>
                        </bxs-collapse-item>
                    </bxs-col> -->

                    <!-- <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">Studio e fidelizzazione</h5>
                            </template>

                            <p class="mb-0 text-mute">Studio e fidelizzazione di una community per la crescita di un brand o di una persona giuridica</p>
                        </bxs-collapse-item>
                    </bxs-col> -->

                    <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">Esports</h5>
                            </template>

                            <p class="mb-0 text-mute">Creazione e gestione di tornei online; organizzazione e produzione eventi live con annessa gestione streaming.</p>
                        </bxs-collapse-item>
                    </bxs-col>

                    <!-- <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">Ecommerce</h5>
                            </template>

                            <p class="mb-0 text-mute">Assets per pro streaming, corsi online per regista e talent management.</p>
                        </bxs-collapse-item>
                    </bxs-col> -->

                    <!-- <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">App e WebApp</h5>
                            </template>

                            <p class="mb-0 text-mute">Assets per pro streaming, corsi online per regista e talent management.</p>
                        </bxs-collapse-item>
                    </bxs-col> -->

                    <!-- <bxs-col cols="12" ds="6">
                        <bxs-collapse-item>
                            <template #label>
                                <h5 class="mb-0">Radio e podcast</h5>
                            </template>

                            <p class="mb-0 text-white-darken-1">Gestione di webradio e contenuti podcast, <button>progetto “Radio Statale”</button>.</p>
                        </bxs-collapse-item>
                    </bxs-col> -->
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- customers -->
        <section>
            <bxs-layout>
                <div class="text-center">
                    <p>Diversi brand dal nostro percorso lavorativo</p>
                </div>

                <bxs-row
                align="center"
                justify="center"
                class="mb-ui">
                    <bxs-col cols="6" ds="3">
                        <a
                        href="https://www.bmw.it/it/home.html"
                        target="_blank"
                        style="display: block;">
                            <img src="/img/customers/bmw.png" alt="Logo cliente BMW" class="customers-logo">
                        </a>
                    </bxs-col>

                    <bxs-col cols="6" ds="3">
                        <a
                        href="https://www.acmilan.com/it"
                        target="_blank"
                        style="display: block;">
                            <img src="/img/customers/acm.png" alt="Logo cliente AC Milan" class="customers-logo">
                        </a>
                    </bxs-col>

                    <bxs-col cols="6" ds="3">
                        <a
                        href="https://it.idntt.ch"
                        target="_blank"
                        style="display: block;">
                            <img src="/img/customers/idntt.png" alt="Logo cliente idntt.ch" class="customers-logo">
                        </a>
                    </bxs-col>

                    <bxs-col cols="6" ds="3">
                        <a
                        href="https://www.unimi.it/it"
                        target="_blank"
                        style="display: block;">
                            <img src="/img/customers/universita-degli-studi-milano.png" alt="Logo cliente Università degli studi milano" class="customers-logo">
                        </a>
                    </bxs-col>

                    <bxs-col cols="6" ds="3">
                        <a
                        href="https://www.tuttomercatoweb.com"
                        target="_blank"
                        style="display: block;">
                            <img src="/img/customers/tutto-mercato-web.png" alt="Logo cliente Tutto mercato web" class="customers-logo">
                        </a>
                    </bxs-col>

                    <bxs-col cols="6" ds="3">
                        <a
                        href="https://www.cusmilano.it"
                        target="_blank"
                        style="display: block;">
                            <img src="/img/customers/cus-milano.png" alt="Logo cliente CUS-Milano" class="customers-logo">
                        </a>
                    </bxs-col>

                    <bxs-col cols="6" ds="3">
                        <a
                        href="https://www.cittadisangiuliano.it"
                        target="_blank"
                        style="display: block;">
                            <img src="/img/customers/sangiuliano-city.png" alt="Logo cliente sangiuliano city" class="customers-logo">
                        </a>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- cta -->
        <section>
            <bxs-layout>
                <bxs-row
                align="center"
                justify="between"
                class="grad-1 radius pa-ui">
                    <bxs-col cols="12" ds="8">
                        <p class="h2 mb-2">Concretizziamo le tue idee</p>
                        <div style="max-width: 560px;">
                            <p>Offriamo un servizio customizzato fatto apposta per le tue necessità, dalla cura del tuo canale streaming alla tua immagine sui social.</p>
                        </div>
                    </bxs-col>

                    <bxs-col cols="12" ds="4">
                        <bxs-btn
                        append-icon="arrow-down"
                        color="white"
                        dark
                        block
                        @click="scollToContact">Richiedi un appuntamento</bxs-btn>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- media -->
        <section class="black-darken-4">
            <bxs-layout>
                <bxs-row>
                    <bxs-col cols="12" ds="4">
                        <p class="h6 text-uppercase">Seguici sui nostri social</p>

                        <div style="font-size: 2rem;">
                            <a
                            href="https://www.instagram.com/com.unity.productions"
                            target="_blank"
                            class="mr-ui">
                                <bxs-icon name="ig"></bxs-icon>
                            </a>

                            <a
                            href="https://twitter.com/com_unitytv"
                            target="_blank"
                            class="mr-ui">
                                <bxs-icon name="tw"></bxs-icon>
                            </a>

                            <a
                            href="https://www.linkedin.com/company/com-unity-productions"
                            target="_blank">
                                <bxs-icon name="li"></bxs-icon>
                            </a>
                        </div>
                    </bxs-col>

                    <bxs-col cols="12" ds="8">
                        <img src="/img/tue-idee.jpg" alt="Foto di Stefan in regia" class="radius">

                        <!-- <video
                        preload="auto"
                        playsinline=""
                        autoplay=""
                        muted=""
                        loop=""
                        poster="">
                            <source src="/img/cus-milano-comunity.mov" type="video/mp4">
                            Your browser does not support HTML5 video.
                        </video> -->
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- form -->
        <section
        id="contact"
        class="black-darken-3 light">
            <bxs-layout max-width="768">
                <div class="mb-ui">
                    <bxs-chip
                    class="mb-ui"
                    small
                    outlined>Contatti</bxs-chip>

                    <p class="h4 text-ui mb-2">Scrivici e lascia il tuo messaggio, <br> ti ricontatteremo appena possibile.</p>
                    <p class="text-mute">
                        <router-link to="/privacy-policy">
                            <small>Prendi visione delll'informativa sulla privacy di com-unity.tv</small>
                            <bxs-icon name="arrow-top-right" class="ml-4" />
                        </router-link>
                    </p>
                </div>

                <div>
                    <div v-if="form.get.loading">
                        <p>Caricamento form ...</p>
                    </div>

                    <div v-else>
                        <div v-if="!form.get.on">
                            <p class="mb-0">Ops, qualcosa è andato storto.</p>
                            <p>{{ form.get.error }}</p>
                        </div>

                        <bxs-form
                        v-else
                        ref="contactForm"
                        @submit="submitContactForm">
                            <bxs-row>
                                <bxs-col cols="12" ds="4">
                                    <bxs-text-field
                                    v-model="form.model.name"
                                    label="Nome e cognome"
                                    name="name"
                                    required />
                                </bxs-col>

                                <bxs-col cols="12" ds="4">
                                    <bxs-text-field
                                    v-model="form.model.email"
                                    label="Email"
                                    name="email"
                                    required />
                                </bxs-col>

                                <bxs-col cols="12" ds="4">
                                    <bxs-text-field
                                    v-model="form.model.phone"
                                    label="Numero (facoltativo)"
                                    name="phone" />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="form.model.subject"
                                    label="Oggetto"
                                    placeholder="Appuntamento, informazioni, altro"
                                    name="object"
                                    required />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <bxs-textarea-field
                                    v-model="form.model.message"
                                    label="Messaggio"
                                    name="message" />
                                </bxs-col>

                                <bxs-col cols="12" ds="8">
                                    <bxs-switch-field
                                    v-model="form.model.pp_accept"
                                    label="Ho preso visione dell'informativa sulla privacy" />
                                </bxs-col>

                                <bxs-col cols="12" ds="4" class="text-right">
                                    <bxs-btn
                                    color="primary"
                                    block
                                    :disabled="!form.model.pp_accept || form.post.loading"
                                    @click="$refs.contactForm.submit()">Invia</bxs-btn>
                                </bxs-col>
                            </bxs-row>

                            <div class="mt-ui text-mute">
                                <router-link to="/privacy-policy">
                                    <small>Prendi visione delll'informativa sulla privacy di com-unity.tv</small>
                                    <bxs-icon name="arrow-top-right" class="ml-4" />
                                </router-link>
                            </div>
                        </bxs-form>
                    </div>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    name: 'home',
    data () {
        return {
            form: {
                get: {
                    loading: true,
                    on: false,
                    error: null,
                    data: null
                },
                post: {
                    loading: false,
                    error: null
                },
                model: {
                    name: null,
                    subject: null,
                    email: null,
                    message: null,
                    phone: null,
                    pp_accept: false
                }
            }
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        scollToContact () {
            document.getElementById('contact').scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            })
        },
        async start () {
            this.form.get.error = null
            this.form.get.loading = true

            try {
                this.form.get.data = await this.$bxsHttpContactForm.get('v1/me/forms/' + process.env.VUE_APP_BXS_CF_PK)
                this.form.get.on = true
            } catch (err) {
                this.form.get.error = err
                this.form.get.on = false
            } finally {
                this.form.get.loading = false
            }
        },
        async submitContactForm () {
            this.form.post.loading = true
            this.form.post.error = null

            try {
                await this.$bxsHttpContactForm.post('v1/me/forms/' + process.env.VUE_APP_BXS_CF_PK, this.form.model)
                this.$refs.contactForm.reset()
                toast.success('Operazione completata, ti ricontatteremo appena possibile', {
                    timeout: 6000
                })
            } catch (err) {
                this.form.post.error = err

                toast.error('Ops, qualcosa è andato storto, riprova tra poco', {
                    timeout: 6000
                })
            } finally {
                this.form.post.loading = false
            }
        }
    }
}
</script>
