<template>
    <bxs-navbar />

    <!-- view -->
    <main class="app__main">
        <router-view
        :key="$route.fullPath"
        class="app__page" />
    </main>

    <bxs-footer />
</template>

<script>
import { fixVh } from '@/assets/libs/utils'

import Navbar from '@/components/layout/Navbar.vue'
import Footer from '@/components/layout/Footer.vue'

export default {
    name: 'App',
    components: {
        'bxs-navbar': Navbar,
        'bxs-footer': Footer
    },
    mounted () {
        fixVh()
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>