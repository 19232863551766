<template>
    <button
    v-if="!to && !href"
    ref="button"
    :type="type"
    rel="noopener"
    :disabled="disabled"
    :role="role"
    :class="classes"
    :style="styles">
        <bxs-icon
        v-if="prependIcon"
        :name="prependIcon"
        class="bxs-btn-icon-prepend" />

        <slot />

        <bxs-icon
        v-if="appendIcon"
        :name="appendIcon"
        class="bxs-btn-icon-append" />
    </button>

    <router-link
    v-else-if="!!to && !href"
    ref="button"
    :role="role"
    rel="noopener"
    :to="to"
    :disabled="disabled"
    :class="classes"
    :style="styles">
        <bxs-icon
        v-if="prependIcon"
        :name="prependIcon"
        class="bxs-btn-icon-prepend" />

        <slot />

        <bxs-icon
        v-if="appendIcon"
        :name="appendIcon"
        class="bxs-btn-icon-append" />
    </router-link>

    <a
    v-else-if="!to && !!href"
    ref="button"
    :href="href"
    rel="noopener"
    :target="target"
    :disabled="disabled"
    :class="classes"
    :style="styles">
        <bxs-icon
        v-if="prependIcon"
        :name="prependIcon"
        class="bxs-btn-icon-prepend" />

        <slot />

        <bxs-icon
        v-if="appendIcon"
        :name="appendIcon"
        class="bxs-btn-icon-append" />
    </a>
</template>

<script>
import { measurable } from '@/mixins'

export default {
    name: 'bxs-btn',
    mixins: [measurable],
    props: {
        type: {
            type: String,
            required: false,
            default: 'button'
        },
        role: {
            type: String,
            required: false,
            default: 'button'
        },
        block: {
            type: Boolean,
            required: false,
            default: false
        },
        text: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false,
            default: null
        },
        href: {
            type: String,
            required: false,
            default: null
        },
        target: {
            type: String,
            required: false,
            default: '_blank'
        },
        color: {
            type: String,
            required: false,
            default: null
        },
        'prepend-icon': {
            type: String,
            required: false,
            default: null
        },
        'append-icon': {
            type: String,
            required: false,
            default: null
        },
        icon: {
            type: Boolean,
            required: false,
            default: false
        },
        fab: {
            type: Boolean,
            required: false,
            default: false
        },
        pill: {
            type: Boolean,
            required: false,
            default: false
        },
        'justify-between': {
            type: Boolean,
            required: false,
            default: false
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        'x-small': {
            type: Boolean,
            required: false,
            default: false
        },
        large: {
            type: Boolean,
            required: false,
            default: false
        },
        'text-align': {
            type: String,
            required: false,
            default: null
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false
        },
        tile: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        dark: {
            type: Boolean,
            required: false,
            default: false
        },
        light: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['bxs-btn', {
                dark: this.dark,
                light: this.light,
                'bxs-btn-block': this.block,
                'bxs-btn-text': this.text,
                'bxs-btn-icon': this.icon,
                'bxs-btn-fab': this.fab,
                'bxs-btn-pill': this.pill,
                'bxs-btn-tile': this.tile,
                'bxs-btn-justify-between': this.justifyBetween,
                ['bxs-btn-' + this.color]: !!this.color,
                'bxs-btn-small': this.small,
                'bxs-btn-x-small': this.xSmall,
                'bxs-btn-outlined': this.outlined,
                'bxs-btn-large': this.large,
                'bxs-btn-prepend-icon': this.prependIcon,
                'bxs-btn-append-icon': this.appendIcon,
                'bxs-btn-active': this.active,
                'bxs-btn-disabled': this.disabled,
                ['bxs-btn-text-align-' + this.textAlign]: this.textAlign
            }]
        },
        styles () {
            return this.measurable_styles
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/conf.scss';

.bxs-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    min-width: var(--button-min-width);
    min-height: var(--button-min-height);

    background-color: var(--button-background-color);
    color: var(--button-color);

    padding: var(--button-padding-y) var(--button-padding-x);

    // border: var(--thickness) solid transparent;
    border-radius: var(--radius);

    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    text-align: center;
    text-decoration: none;
    line-height: normal;
    letter-spacing: var(--button-letter-spacing);

    vertical-align: middle;
    transition: transform 0.5s ease-out;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: var(--button-color);
    }

    &:hover {
        background-color: var(--button-hover-background-color);

        &.bxs-btn-outlined {
            border-color: transparent;
            color: var(--button-color);
            background-color: var(--button-hover-background-color);
        }

        &.bxs-btn-icon,
        &.bxs-btn-text {
            border-color: transparent;
            background-color: var(--button-hover-background-color);
        }
    }

    // colors

    @each $key, $value in $colors {
        &.bxs-btn-#{$key} {
            background-color: var(--color-#{$key});

            // @if $key == primary {
            //     color: var(--color-white);
            // } @else {
            //     color: var(--color-typo);
            // }

            &.bxs-btn-text {
                color: var(--color-#{$key});

                &:hover {
                    border-color: var(--color-#{$key});
                    color: var(--color-#{$key});
                }
            }

            &.bxs-btn-outlined {
                color: var(--color-#{$key});
                border-color: var(--color-#{$key});
            }

            &.bxs-btn-icon {
                color: var(--color-#{$key});
            }
        }
    }

    &.bxs-btn-disabled {
        pointer-events: none;
    }

    // sizings

    &.bxs-btn-x-small {
        min-height: inherit;
        font-size: calc(var(--button-font-size) / 1.4);
        padding: calc(var(--button-padding-y) / 1.4) calc(var(--button-padding-x) / 1.4);
    }

    &.bxs-btn-small {
        min-height: inherit;
        font-size: calc(var(--button-font-size) / 1.2);
        padding: calc(var(--button-padding-y) / 1.2) calc(var(--button-padding-x) / 1.2);
    }

    &.bxs-btn-large {
        font-size: calc(var(--button-font-size) * 1.2);
        padding: calc(var(--button-padding-y) * 1.2) calc(var(--button-padding-x) * 1.2);
    }

    //

    &.bxs-btn-outlined {
        background-color: transparent;
        border: var(--thickness) solid var(--button-color);
        color: var(--button-color);
    }

    &.bxs-btn-tile {
        border-radius: 0;
    }

    // &.router-link-active {
    //     &::before {
    //         height: var(--thickness);
    //     }
    // }

    // &.bxs-btn-active {
    // }

    &.bxs-btn-justify-between {
        justify-content: space-between;
    }

    &.bxs-btn-text-align-left {
        justify-content: flex-start;
        text-align: start;
    }

    &.bxs-btn-block {
        flex: 1;
        display: flex;
        width: 100%;
        max-width: 100%;
    }

    &.bxs-btn-text {
        background-color: transparent;
        color: var(--color-typo);

        &.router-link-active {
            &::before {
                height: var(--thickness);
            }
        }
    }

    &.bxs-btn-fab {
        justify-content: center;
        width: var(--button-min-height);
        height: var(--button-min-height);
        min-width: auto;
        min-height: auto;
        padding: 0;
        border-radius: var(--pill);

        // &.router-link-active {
        //     &::before {
        //         background-color: transparent;
        //         border: var(--thickness) solid var(--button-color);
        //         height: 100%;
        //         border-radius: var(--pill);
        //     }
        // }
    }

    &.bxs-btn-pill {
        border-radius: var(--pill);
    }

    &.bxs-btn-icon {
        background-color: transparent;
        color: var(--color-typo);
        padding: 0;
        // min-width: inherit;
        // min-height: inherit;
    }

    &.bxs-btn-prepend-icon {
    }
    &.bxs-btn-append-icon {
    }

    > .bxs-icon {
        width: var(--icon-nest-size) !important;
        height: var(--icon-nest-size) !important;

        &.bxs-btn-icon-prepend {
            margin-right: var(--button-icon-margin-x);
        }

        &.bxs-btn-icon-append {
            margin-left: var(--button-icon-margin-x);
        }
    }
}
</style>