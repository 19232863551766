import axios from 'axios'
import qs from 'qs'

export default class HTTP {
    constructor(config = {}, emitter) {
        this.config = config
        this.emitter = emitter

        this.http = axios.create({
            baseURL: this.config.base_url,
            withCredentials: true,
            timeout: this.config.timeout || 180000, // 3m
            headers: {
                [this.config.prefix + '-requester-lang']: this.config.requester_Lang,
                'x-app-id': this.config.app_id
            },
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat',
                    indices: false,
                    allowDots: false,
                    skipNulls: true,
                    format: 'RFC1738'
                })
            },
            validateStatus: status => status >= 200 && status < 400
        })

        return this
    }

    // publics

    options(resource, params) {
        return new Promise((resolve, reject) => {
            this.http.options(resource, { params: params }).then((res) => {
                return resolve(res.data)
            }).catch((err) => {
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    get(resource, params) {
        return new Promise((resolve, reject) => {
            this.http.get(resource, { params: params }).then((res) => {
                return resolve(res.data)
            }).catch((err) => {
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    post(resource, data, headers) {
        return new Promise((resolve, reject) => {
            this.http.post(resource, data, { headers: headers }).then((res) => {
                return resolve(res.data)
            }).catch((err) => {
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    put(resource, data) {
        return new Promise((resolve, reject) => {
            this.http.put(resource, data)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err.response && err.response.data ? err.response.data : err.response))
        })
    }

    patch(resource, data) {
        return new Promise((resolve, reject) => {
            this.http.patch(resource, data)
                .then((res) => resolve(res.data))
                .catch(err => reject(err.response && err.response.data ? err.response.data : err.response))
        })
    }

    delete(resource, data, headers) {
        return new Promise((resolve, reject) => {
            this.http.delete(resource, {
                data: data,
                headers: headers
            })
                .then(res => resolve(res.data))
                .catch((err) => reject(err.response && err.response.data ? err.response.data : err.response))
        })
    }
}
